interface Props {
  template: any
  download: any
  preview: any
}


export default function ResController(props: Props) {
  const controls = [
    { 'name': 'Templates', 'onClick': props.template, 'svgData': 'M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z' },
    { 'name': 'Preview', 'onClick': props.preview, 'svgData': 'M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z' },
    { 'name': 'Download', 'onClick': props.download, 'svgData': 'M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z' }
  ]
  return (
    <ul className='flex gap-4 text-gray-800 h-full bg-gray-100 text-base justify-around md:justify-start md:pl-10'>
      {controls.map((item) => (
        <li className='inline-flex items-center text-gray-700 hover:text-gray-600 rounded cursor-pointer' onClick={item.onClick}>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
            <path fill-rule='evenodd' d={item.svgData} clip-rule='evenodd' />
          </svg>
          <span className='font-bold'>{item.name}</span>
        </li>
      ))}
    </ul>
  )
}