import React, { ReactElement, useEffect } from 'react';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-latex";
import "ace-builds/src-noconflict/theme-xcode";


interface Props {
  className?: string,
  templateCode: string,
  setCode: any
}

export default function CodingPanel(props: Props): ReactElement {
  const aceEditor = React.useRef<any>(null);

  // change the font fmaily of the editor
  const useMountEffect = (() => useEffect(() => {
    aceEditor.current.editor.setOptions({
      fontFamily: 'Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace',
      fontSize: '1rem'
    })
  }))
  useMountEffect()

  // on editor changed, set the code to the parent state
  const editorOnChange = (changedCode: string) => {
    props.setCode(changedCode)
  }

  // set loaded template code and clear selection once the template is loaded
  useEffect(() => {
    if (props.templateCode !== '' && props.templateCode) {
      aceEditor.current.editor.setValue(props.templateCode)
      aceEditor.current.editor.clearSelection()
    }
    return () => {
      // cleanup
    }
  }, [props.templateCode])

  return (
    <AceEditor ref={aceEditor} mode='latex' theme='xcode' height='100%' width='100%' onChange={editorOnChange}
    />
  )
}
