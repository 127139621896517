import React, { ReactElement } from 'react'
import { PDFViewer } from 'react-view-pdf-mod';

interface Props {
  className?: string
  code: string,
  setRef: any
}

export default function PreviewPanel(props: Props): ReactElement {

  return (
    <PDFViewer disableSelect={true} url={props.code !== '' ? props.code : "/assets/default.pdf"} />
  )
}
