import React, { useEffect, useState } from 'react'
import { getResume, getTemplateByName } from '../utils/axios.service'
import CodingPanel from './CodingPanel'
import TemplateModal from './TemplateModal'
import PreviewPanel from './PreviewPanel'
import ResController from './ResController'
import { TemplateIds } from '../types/template-ids'

export default function Home() {
  let previewRef: any = null

  const [code, setCode] = useState('')
  const [previewCode, setPreviewCode] = useState('')
  const [pdfURL, setPdfURL] = useState('')
  const [err, setErr] = useState(false)
  const [errCode, setErrCode] = useState('')
  const [displayTemplate, setDisplayTemplate] = useState(false)

  const templateBtn = () => {
    setDisplayTemplate(!displayTemplate)
  }
  const downloadBtn = () => {
    if (pdfURL !== '') {
      downloadFile()
    }
    else {
      if (code !== '')
        getResume(code).then(res => {
          // revoke previous url for memory optimization
          if (pdfURL !== '') {
            URL.revokeObjectURL(pdfURL)
          }
          setPdfURL(URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' })))

          // await for 3 seconds to download since createObjectURL takes a few seconds
          setTimeout(() => {
            downloadFile()
          }, 3000);
        }).catch(err => {
          err.response.data.text().then((errCode: any) => {
            setErrCode(JSON.parse(errCode).detail)
            setErr(true)
          })
        })
      else
        // setErr(true)
        return
    }
  }

  const downloadFile = () => {
    const link = document.createElement('a')
    link.href = pdfURL
    link.download = 'resume.pdf'
    link.click()
  }

  const previewBtn = async () => {
    // revoke previous url
    if (pdfURL !== '') {
      URL.revokeObjectURL(pdfURL)
    }

    if (code !== '')
      // get generated pdf from api if the code is not empty string
      getResume(code).then(res => {
        setPdfURL(window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' })));
        previewRef && previewRef.contentRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      }).catch(async (err) => {
        // error message need to be processed since the response type is blob
        err.response.data.text().then((errCode: any) => {
          setErrCode(JSON.parse(errCode).detail)
          // console.error(JSON.parse(errCode).detail);
          setErr(true)
        })
      })
    else
      // if code is empty, show default text in the alert modal
      // setErr(true)
      return
  }

  const selectTemplate = (templateName: TemplateIds) => {
    // get template from api
    getTemplateByName(templateName).then(res => {
      setPreviewCode(res)
      setDisplayTemplate(false)
    }).catch(err => {
      console.log(err)
    })
  }

  const setRef = (ref: any) => {
    if (ref)
      previewRef = ref
  }

  useEffect(() => {
    // close the alert modal when the timeout ends
    setTimeout(() => {
      setErr(false)
    }, 7000);
    return () => {
      // cleanup
    }
  }, [err])

  return (
    <div className='flex flex-grow'>
      <TemplateModal display={displayTemplate} closeModal={() => { setDisplayTemplate(false) }} selectTemplate={selectTemplate} />
      <div className='flex-1 mb-12'>
        <div className='flex-1 h-12 min-w-screen'>
          <ResController template={templateBtn} download={downloadBtn} preview={previewBtn} />
        </div>
        <div className='flex flex-col sm:flex-row min-w-screen flex-grow h-full w-full'>
          <div className='w-full sm:w-1/2 h-full'>
            <CodingPanel setCode={setCode} templateCode={previewCode} />
          </div>
          <div className='w-full sm:w-1/2 h-full'><PreviewPanel code={pdfURL} setRef={setRef} /></div>
        </div>

      </div>
      {
        err && <div role='alert' className='absolute w-fit sm:bottom-10 bottom-1/2 left-1/2 transform -translate-x-1/2 sm-translate-y-1 -translate-y-1/2 z-[999]'>
          <div className='bg-red-500 text-white font-bold rounded-t px-4 py-2'>
            Danger
          </div>
          <div className='border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700'>
            <p>{errCode !== '' ? errCode : 'Something went wrong!'}</p>
          </div>
        </div>
      }
    </div>
  )
}
