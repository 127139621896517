import React from 'react'

interface Props {

}

export default function About(props: Props) {
  return (
    <div>
        About
    </div>
  )
}
