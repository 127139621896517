import React, { useEffect, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { TemplateIds } from '../types/template-ids'
import { getTemplates } from '../utils/axios.service'
import TemplateContainer from './TemplateContainer'

interface Props {
  display: boolean
  closeModal: any,
  selectTemplate: any
}

export default function TemplateModal(props: Props) {

  const [templates, setTemplates] = useState<{ id: TemplateIds, displayName: string }[]>([])
  const [imageUrl] = useState<any[]>([])

  useEffect(() => {
    templates.length === 0 && getTemplates().then(res => {
      setTemplates(res)
    })
    return () => {
      // cleanup
    }
  }, [props.display, templates])

  const setImageURL = (templateId: TemplateIds, imageURL: string) => {
    for (let i = 0; i < imageUrl.length; i++) {
      if (imageUrl[i].id === templateId) {
        imageUrl[i].imageURL = imageURL
        return
      }
    }

    imageUrl.push({
      id: templateId,
      imageURL
    })
  }

  return (
    <div>
      {
        props.display ?
          <div className='template-modal'>
            <div
              className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[99]"
              id="my-modal"
              onClick={() => {
                props.closeModal()
              }}
            ></div>
            <ScrollContainer hideScrollbars={false} className="modal bg-gray-50 left-1/2 top-1/2  transform -translate-x-1/2 -translate-y-1/2 w-[60%] sm:h-[60%] h-fit sm:py-0 py-3 absolute rounded-md z-[99]
            flex sm:flex-row flex-col sm:gap-5 gap:0 overflow-x-auto overflow-y-hidden">
              {
                templates.map((template, key) => {
                  return <TemplateContainer key={key} index={key} urlList={imageUrl} setImageURL={setImageURL} templateId={template.id} templateName={template.displayName} selectTemplate={props.selectTemplate} />
                })
              }
            </ScrollContainer>
          </div>
          :
          ''
      }
    </div>
  )
}
