import React, { useEffect, useState } from 'react'
import { TemplateIds } from '../types/template-ids'
import { getTemplatePreviewById } from '../utils/axios.service'
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface Props {
  index: number,
  templateId: TemplateIds,
  templateName: string,
  selectTemplate: any,
  urlList: any,
  setImageURL: any
}

export default function TemplateContainer(props: Props) {
  const [imageURL, setImageURL] = useState<string>('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const url = props.urlList[props.index] ? props.urlList[props.index].imageURL : ''
    if (url === '') {
      getTemplatePreviewById(props.templateId).then(res => {
        setImageURL(URL.createObjectURL(new Blob([res], { type: 'image/png' })))
        setLoading(false)
      })
    }
    else {
      setImageURL(url)
      setLoading(false)
    }
  }, [props.urlList, props.index, props.templateId])

  useEffect(() => {
    props.setImageURL(props.templateId, imageURL)
    return () => {
      // cleanup
    }
  }, [props, imageURL])

  return (
    <div className='cursor-pointer hover:opacity-40 m-auto sm:w-fit w-full' onClick={() => props.selectTemplate(props.templateId)}>
      <div className="desktop-container sm:block hidden">
        <div className="image min-w-[300px]">
          {
            loading ?
              <div className='m-auto w-fit'>
                <Loader type="TailSpin" height={50} color='#22c55e' />
              </div>
              :
              <img className='max-h-[385px] w-[300px] object-cover' src={imageURL} alt="template" loading='lazy' />
          }
        </div>
        <div className="text w-fit m-auto text-xl">
          <h3>{props.templateName}</h3>
        </div>
      </div>
      <div className="mobile-container sm:hidden block hover:bg-gray-200 p-3">
        <div className="text w-full m-auto text-xl">
          <h3>{props.templateName} Mobile</h3>
        </div>
      </div>
    </div>
  )
}
