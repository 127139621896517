import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';

function App() {

  return (
    <Router>
      <div className='h-screen flex flex-col'>
        {/* Nav */}
        <div className='w-full p-4 md:pl-10 bg-gray-100'>
          <div className='mx-auto flex flex-row w-full'>
            <div style={{ userSelect: 'none' }} className='mt-auto text-2xl font-bold text-gray-900' >
              LatexResume.com
            </div>
          </div>
        </div>
        {/* App */}
        <div className='flex flex-grow w-full p-0'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
          </Routes>
        </div>
        {/* Footer */}
        <div className='w-full pin-b p-4 text-neutral-800 bg-neutral-200 text-center'>
          Copyright &copy; 2022, LatexResume.com
        </div>
      </div>
    </Router>
  );
}

export default App;