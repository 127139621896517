import axios from "axios";
import { TemplateIds } from "../types/template-ids";

const client = axios.create({
  baseURL: 'https://latexresume.com/api/v1/',
})

// generate pdf from code
export const getResume = async (code: string) => {
  // send a multipart/form-data request to receive a blob type response
  const formData = new FormData()
  formData.append('file', new Blob([code], { type: 'text/plain' }), 'latex.txt')
  const res = await client.post('/resume', formData,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    })
  return res
}

// get template list from server
export const getTemplates = async () => {
  let res = await client.get('/templates')
  return res.data
}

// get template by name from server
export const getTemplateByName = async (name: TemplateIds) => {
  let res = await client.get('/templates/' + name)
  return res.data
}

// get template preview by id from server
export const getTemplatePreviewById = async (name: TemplateIds) => {
  let res = await client.get('/templates/' + name + '/preview', { responseType: 'blob' })
  return res.data
}
